import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

import emailjs from "@emailjs/browser";
// import TextField from "@material-ui/core";

const ContactForm = () => {
  const Container = tw.div`relative`;
  const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
  const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
  const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
  const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft
      ? tw`md:mr-12 lg:mr-16 md:order-first`
      : tw`md:ml-12 lg:ml-16 md:order-last`,
  ]);

  const Image = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
  ]);
  const TextContent = tw.div`lg:py-8 text-center md:text-left`;

  const Subheading = tw(SubheadingBase)`text-center md:text-left`;
  const Heading = tw(
    SectionHeading
  )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

  const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
  const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
  const Textarea = styled(Input).attrs({ as: "textarea" })`
    ${tw`h-24`}
  `;

  const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

  //   const [values, setValues] = useState({
  //     name: "",
  //     email: "",
  //     phone_number: "",
  //     subject: "",
  //     message: "",
  //   });
  //   const [status, setStatus] = useState("");

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     emailjs
  //       .send("service_8key04k", "template_3bzz9oa", values, "p-XqNpmkdIlp-ueiS")
  //       .then(
  //         (response) => {
  //           console.log("SUCCESS!", response);
  //           setValues({
  //             name: "",
  //             email: "",
  //             phone_number: "",
  //             subject: "",
  //             message: "",
  //           });
  //           setStatus("SUCCESS");
  //         },
  //         (error) => {
  //           console.log("FAILED...", error);
  //         }
  //       );
  //   };

  //   useEffect(() => {
  //     if (status === "SUCCESS") {
  //       setTimeout(() => {
  //         setStatus("");
  //       }, 3000);
  //     }
  //   }, [status]);

  //   const handleChange = (e) => {
  //     setValues((values) => ({
  //       ...values,
  //       [e.target.name]: e.target.value,
  //     }));
  //   };

  //   const { register, control, errors, handleSubmit, reset, watch } = useForm({
  //     defaultValues: {
  //       name: "",
  //       email: "",
  //       phone_number: "",
  //       subject: "",
  //       message: "",
  //     },
  //   });

  //   const onSubmit = (data) => {
  //     // Send form email
  //     let templateParams = {
  //       name: data.name,
  //       email: data.email,
  //       telefono: data.phone_number,
  //       subject: data.subject,
  //       message: data.message,
  //     };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8key04k",
        "template_3bzz9oa",
        form.current,
        "p-XqNpmkdIlp-ueiS"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Tu mensaje fue enviado, pronto recibirás respuesta");
        },
        (error) => {
          console.log(error.text);
          alert("Tu mensaje no fue enviado, refresca la pagina");
        }
      );

    e.target.reset();
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft="true">
          <TextContent>
            <Subheading>Contactanos</Subheading>
            <Heading>
              <h1>Cuentanos <span tw="text-primary-500">todos los detalles</span>
              <wbr /> de tu proximo proyecto.</h1>
            </Heading>
            <Description>
              Dejanos tus datos de contacto para poder comunicarnos contigo y
              nos puedas dar todos los detalles del proyecto que quisieras
              realizar.
            </Description>

            <Form ref={form} onSubmit={sendEmail}>
              <Input
                // value={values.email}
                // onChange={handleChange}
                // label="E-mail"
                // type="email"
                // name="email"
                // placeholder="Direccion e-mail"
                // inputRef={register({
                //   required: {
                //     value: true,
                //     message: "Introduzca su nombre y apellidos",
                //   },
                // })}
                name="email"
                label="Email"
                type="email"
                placeholder="Direccion e-mail"
              />
              <Input
                // value={values.name}
                // onChange={handleChange}
                label="Nombre Completo"
                type="text"
                name="name"
                placeholder="Nombre Completo"
              />
              <Input
                // value={values.phone_number}
                // onChange={handleChange}
                label="Numero de Telefono"
                type="tel"
                name="phone_number"
                placeholder="Numero de Telefono"
              />
              <Input
                // value={values.subject}
                // onChange={handleChange}
                label="Asunto"
                type="text"
                name="subject"
                placeholder="Asunto"
              />
              <Textarea
                // value={values.message}
                // handleChange={handleChange}
                // label="Mensaje"
                name="message"
                placeholder="Escribe tu mensaje aqui"
              />
              <SubmitButton type="submit">Enviar</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

const renderAlert = () => (
  <div className="px-4 py-3 leading-normal text-blue-700 bg-blue-100 rounded mb-5 text-center">
    <p>your message submitted successfully</p>
  </div>
);

export default ContactForm;
