import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from "components/misc/Headings";

import Trabajosant from "../components/features/VerticalWithAlternateImageAndText";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold my-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "CURRICULUM (APYCEM S.A. DE C.V.)" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Trabajosant></Trabajosant>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading><h1>{headingText}</h1></Heading>
          </HeadingRow>
          <Text>

            <p>APYCEM S.A. DE C.V.</p>

            <p>NECAXA # 805 NTE COL. FELIPE CARRILLO PUERTO                                 CD. MADERO TAMPS. TEL/FAX: 216-87-55  
E-mail: apie04@prodigy.net.mx</p>

            <h2>PRINCIPALES OBRAS EJECUTADAS Ing. Cruz Rolando Guerrero Castilla. Y Actual APYCEM</h2>
            <ul>
            <li>CONSTRUCCIÓN DE GASOLINERA EL SÁBALO PROYECTO COMPLETO 1998</li>
<li>

MANTENIMIENTOS ELECTRICOS Y MECANICOS A ESTACIONES DE SERVICIOS (GASOLINERAS) ASÍ COMO OBRA CIVIL DE LAS MISMAS.</li>
<li>

CONSTRUCCIÓN DE OBRAS ELÉCTRICAS EN GASOLINERA DE COLONIAS Y GPO GASOLINERO. 2001,2002,2003</li>
<li>

CONSTRUCCIÓN TOTAL DE LA OBRA ELÉCTRICA INCLUYENDO MONTAJE DE EQUIPOS ASÍ COMO SUBESTACIÓN ELÉCTRICA. DE CARL 'S JR. (AVE HIDALGO Y AVE UNIVERSIDAD) 2006,2007</li>
<li>

REMODELACION  Y CONSTRUCCION DE MODULOS DEL HOTEL EN EL CLUB DE REGATAS CORONA   (CAMPAMENTO  LAS  GAVIOTAS). 2009,2010</li>
<li>

MANTENIMIENTO EN DIFERENTES ÁREAS DE CRC (CLUB DE REGATAS CORONA A.C.) IMPERMEABILIZACION DE AREA DE CANCHAS RESTAURANTES, REPARACIÓN DE ALUMBRADO EN CANCHAS DE TENIS, REMODELACIÓN DE POSTERIA EN CAMPO DE FUT-BOL Y ALUMBRADO.</li>
<li>

REMODELACIÓN COMPLETA  A MÓDULOS DE  POLICÍA DE BARRIO DEL  AYUNTAMIENTO DE TAMPICO. 2008,2009</li>
<li>

REMODELACIONES GENERALES EN DIFERENTES ÁREAS PERTENECIENTES AL AYUNTAMIENTO DE TAMPICO COMO SON CARCEL, OFICINA DE COMANDANTE , CASA ADULTO MAYOR (DIF), CONSULTORIO ZONA NORTE, DELEGACION ZONA NORTE, DETENCIÓN MENORES, DISPENSARIO COL. TOLTECA, OFICINA DELEGADO, OFICINA OPERATIVO ZONA NORTE, DESARROLLO SOCIAL Y COMEDOR. 2008</li>
<li>

CONSTRUCCION Y  AMPLIACION AREA DE LOCKERS EN CFE DIVISIÓN GOLFO CENTRO. 2007</li>
<li>

REPARACIÓN DE MUROS Y CONSTRUCCIÓN DE OFICINA EN ALMACÉN DE CFE ZONA RÍO VERDE  2007</li>
<li>

SERVICIO DE CHAPOLEO DEL  ALMACÉN DE POSTERIA, CAMPO DE PRÀCTICA DEL INTERIOR DEL ALMACÈN DIVISIONAL, RETIRO DE BASURA, PODA Y ENCALADO DE ÀRBOLES EN CFE 2006,2007</li>
<li>

CEPAS Y BANQUETAS PARA CFE 2007,2008</li>
<li>

CONSTRUCCIÓN DE CUBÍCULOS DE 2.00 X 2.00 X 1.20 MTS DEL ALMACÉN DIVISIONAL DE CFE 2007</li>
<li>

MANTENIMIENTO Y LIMPIEZA DE LAS ÁREAS VERDES EN LAS SUBESTACIONES DE DISTRIBUCIÓN DE LA ZONA TAMPICO DE CFE</li>
<li>

MANTTO AL TANQUE DE ALMACENAMIENTO DE ACEITE DIELÉCTRICO, UBICADO EN EL ALMACÉN DIVISIONAL DE CFE</li>
<li>

ADECUACIÓN DEL DEPARTAMENTO DE ORGANIZACIÓN Y PROCEDIMIENTOS DIVISIONAL DE CFE</li>
<li>

IMPERMEABILIZACIÓN EN LOSA DE CASA PROPIEDAD DE CFE, ASIGNADA A LA ADMINISTRACIÓN DIVISIONAL</li>
<li>

CHAPOLEO Y LIMPIEZA DE SUBESTACIONES DE DISTRIBUCIÓN UBICADAS EN EL ÁMBITO DE LA ZONA MANTE DE CFE</li>
<li>

LIMPIEZA Y DESHIERBE DE MALEZA LIGERA Y APLICACIÓN DE HERBICIDAS EN ALMACÉN DE KM 14 DE CFE</li>
<li>

CONSTRUCCION DE OBRA ELÉCTRICA DE DAIRY QUEEN Ubicado en Ave. Universidad INCLUYENDO SUBESTACIÓN ELÉCTRICA 2012</li>
<li>

OBRA ELECTRICA  EN POPEYES Ubicado Centro Histórico de Tampico INCLUYENDO LA SUBESTACIÓN ELÉCTRICA COMPARTIDA PARA  LA FERRETERÍA EL GALLO.</li>
<li>

SERVICIO DE MANTENIMIENTO ELECTRICO RESIDENCIAL, SERVICIO DE CONSTRUCCIÓN DE REDES ELÉCTRICAS EN MEDIA TENSIÓN. </li>
<li>
SERVICIO  Y CONSTRUCCIÓN DE SUBESTACIONES ELÉCTRICAS MONOFÁSICAS Y TRIFÁSICAS A CLIENTES PARTICULARES.</li>
<li>

INSTALACIÓN DE ACOMETIDAS Y MANTENIMIENTO A PUENTES PEATONALES UBICADOS EN EL PUERTO DE TAMPICO.</li>
<li>

CONSTRUCCION DE CAFETERIA EN INTERIOR DE GASOLINERA BRARO DE CD MADERO.</li>
<li>

REMODELACIONES Y CONSTRUCCION EN GASOLINERA BARAL S.A. DE C.V. COMO BARDAS, CUARTO DE SERVICO, COCHERA ETC.</li>
<li>

PROYECTO ELÉCTRICO PARA UFLEX AMÉRICAS UBICADO EN EL PUERTO INDUSTRIAL DE ALTAMIRA.</li>
<li>

SERVICIO DE IMPERMEABILIZACIÓN A CLIENTES PARTICULARES</li>
<li>

OBRA CIVIL Y ELECTRICA: CONSTRUCCION DE GASOLINERA EN PLAYA DEL CARMEN QUINTANA ROO. 2005</li>
<li>

CONSTRUCCION DE ESTACION DE AUTOCONSUMO UBICADA EN SANTA AMALIA, ALTAMIRA, TAMPS.2006</li>
<li>

CONSTRUCCION DE OBRA CIVIL, ELÉCTRICA, PINTURA ETC. DE TRES DEPARTAMENTOS UBICADOS EN COL. MANUEL R. DÍAZ 2007</li>
<li>

CONSTRUCCION DE OBRA CIVIL, ELÉCTRICA, PINTURA ETC. DE TRES DEPARTAMENTOS UBICADOS EN MATAMOROS COL. AMPLIACION UNIDAD NACIONAL 2011</li>
<li>

CONSTRUCCION DE OBRA CIVIL, ELÉCTRICA, PINTURA ETC. DE SEIS DEPARTAMENTOS UBICADOS EN COL. DELFINO RESENDIZ 2006</li>
<li>

CONSTRUCCION DE OBRA CIVIL, ELÉCTRICA, PINTURA ETC. DE TRES DEPARTAMENTOS UBICADOS EN RAUL CASTILLO COL. DELFINO RESENDIZ 2011</li>
<li>

CONSTRUCCION DE OBRA CIVIL, ELÉCTRICA, PINTURA ETC. DE TRES DEPARTAMENTOS UBICADOS EN NECAXA COL. FELIPE CARRILLO PUERTO 2011</li>
            </ul>

            <h2>PRINCIPALES CLIENTES: ESTACIONES DE SERVICIO CONSTRUIDAS EN OBRA ELECTRICA Y MECANICA ASI COMO PROYECTOS COMPLETOS 1995 a la fecha</h2>
            
            <ul>
            <li>SÚPER SERVICIO UNIDAD S.A. de C.V. Av. Monterrey # 601 Pte. Cd. Madero Tamps. ELECTROMECANICA </li>
<li>

SERVICIO YUGAR S.A. de C.V. Av. Madero # 401 Pte. Col. Unidad Nacional. Cd. Madero Tamps  ELÉCTRICA</li>
<li>

SERVICIO YUGAR S.A. de C.V. Jiménez # 605 Pte. Cd. Madero Tamps.  ELÉCTRICA</li>
<li>

SERVICIO 18 DE MARZO S.A. de C.V. Av. Tamaulipas e Insurgentes Ampl. Unidad Nacional. Cd. Madero Tamps.  ELECTROMECANICA</li>
<li>

SERVICIO 18 DE MARZO S.A. de C.V. 1° de Mayo # 1401 Pte. Col. Lázaro Cárdenas. Cd. Madero. Tamps   ELÉCTRICA</li>
<li>

SERVICIO BOULEVARD S.A. de C.V. Blvd. A. López Mateos # 700 Col. Esfuerzo Nacional Cd. Madero Tamps  ELÉCTRICA</li>
<li>

BRARO S.A. de C.V. Francisco I Madero # 601 Sur. Cd. Madero Tamps ELECTROMECANICA</li>
<li>

JUAN ARTURO BIOTEGUI ETIENNE Chihuahua y Mérida s/n Col. Hidalgo Cd. Madero Tamps.   ELÉCTRICA </li>
<li>

SERVICIO LIBRAMIENTO MIRAMAR S.A. DE C.V. Corredor Urbano Km.  3+740 Cd. Madero-Altamira Cd. Madero Tamps.  ELÉCTRICA</li>
<li>

SERVICIO LIBRAMIENTO MIRAMAR S.A. de C.V. Corredor Urbano Luis D. Colosio Murrieta Km 3+715 Cd. Madero Tamps. ELÉCTRICA</li>
<li>

ES EL BARQUITO S.A. DE C.V. Carretera Tampico-Mante Km. 13 Altamira Tamps. ELECTROMECANICA</li>
<li>

SUPER SERVICIO ESTADIO S.A. DE C.V. Guatemala # 1500 Pte. Col. Vicente Guerrero Cd. Madero Tamps. ELÉCTRICA</li>
<li>

SERVICIO EL JAIBO Ave. Hidalgo # 702 Esq. Torreón Col. Cambell Tampico, Tamps   ELECTROMECANICA</li>
<li>
SERVICIO TANCOL S.A. DE C.V.  Ave. Rivera de Champayan #203 Col. San Antonio, Tampico, Tamps.  ELÉCTRICA</li>
<li>

SERVICIO BARAL S.A. DE C.V. Carret. Tampico-Mante#800 Col. Las Américas, Tampico, Tamps.  ELÉCTRICA</li>
<li>

SERVICIOS GASOLINEROS SÁNCHEZ JUÁREZ S.A. de C.V. Ave. Monterrey # 113 Nte. Esq. Con Manzanillo Fracc. Los Cedros Cd. Madero, Tamps.  ELECTROMECANICA</li>
<li>

GASOLINERA DE LA VICENTE GUERRERO DE CD. MADERO S.A. DE C.V. Ave. Álvaro Obregón # 1201 Col. Benito Juárez Cd. Madero Tamps. ELECTROMECANICA</li>
<li>

GASOLINERA DE LA VICENTE GUERRERO DE CD. MADERO S.A. DE C.V. Ave. Álvaro Obregón # 1201 Col. Benito Juárez Cd. Madero Tamps. (HEB). ELECTROMECANICA</li>
<li>

GASOLINERA DE COLONIAS DE TAMPICO S.A. DE C.V. Ave. Cuauhtemoc # 1904 Col. Rodríguez Tampico, Tamps.  ELECTROMECANICA</li>
<li>

GRUPO GASOLINERO Y AUTOMOTRIZ DE TAMAULIPAS S.A. DE C.V. Ave. Hidalgo # 4107 Fracc. Florida Tampico, Tamps.  ELECTROMECANICA</li>
<li>

SERVICIO DE GASOLINERA HIDALGO S.A. de C.V. Ave. Hidalgo # 1913 Col. Smith Tampico, Tamps.  ELÉCTRICA</li>
<li>

UNIÓN DE GASOLINEROS DEL SUR DE TAMAULIPAS S.A. de C.V. Ave. Hidalgo Esq. Jesús Elías Piña, s/n Col.del Pueblo Tampico, Tamps  ELÉCTRICA</li>
<li>

COMBUSTIBLES DE TANTOYUCA S.A. de C.V. Carretera. Nacional Tuxpan-Tampico Km. 63+080. Tantoyuca Veracruz.  ELECTROMECANICA</li>
<li>

SERVICIO SAROGAR S.A. de C.V. Carret. Valles-Tampico Km. 45+800. Tamuín. San Luis Potosí  ELÉCTRICA</li>
<li>

SUPER SERVICIO DE LA HUASTECA S.A. DE C.V. Blvd. S. Díaz Mirón Esq. Carretera Panuco-Tempoal. Pánuco Ver.   ELÉCTRICA</li>
<li>

SERVICIOS DE COMBUSTIBLE PANUCO S.A. de C.V. Carretera. Panuco-Canoas Km 1+000 Paso Real Bna Vista Panuco Ver.  ELÉCTRICA</li>
<li>


SERVICIO YUGAR S.A. DE C.V. Carretera Nacional y Enrique Rebsamen # 1002 Tantoyuca Ver.  ELÉCTRICA</li>
<li>

SERVICIO YUGAR S.A. DE C.V.  Km. 175+600 Cd. Cuauhtémoc Ver.ELÉCTRICA</li>
<li>

SERVICIO PLATON SANCHEZ S.A. DE C.V. Carretera Nacional México-Tampico Km 234. Platón Sánchez Ver.  ELECTROMECANICA</li>
<li>

SERVICIO ATLAPEXCO S.A. de C.V. Carretera Atlapexco Huautla Km 114 Col. Teocuatitla Atlapexco Hidalgo.  ELECTROMECANICA</li>
<li>

CLARA LUZ GARCÍA HERNÁNDEZ Carret. Tuxpan-Tampico Km 139 Col. El Alto. Tempoal Ver.  ELÉCTRICA</li>
<li>

SERVICIO CD. VALLES S.A. de C.V. Miguel Hidalgo # 523-B Cd. Valles S.L.P. ELÉCTRICA</li>
<li>

IMPULSORA ALLENDE S.A. de C.V. Tamaulipas # 401 Ote. Zona Centro Tampico, Tamps.  ELÉCTRICA</li>
<li>

LOGA DE TAMPICO S.A. DE C.V. Ave. Monterrey #117 Col. Enrique Cárdenas González, Tampico, Tamps.</li>
<li>

GERTELL COMBUSTIBLES S.A. DE C.V. Cuauhtemoc s/n Col. Cap Antonio Reyes, Huejutla Hgo.  COMPLETA</li>
<li>

ESTACIÓN DE SERVICIO TULA TAMAULIPAS PROYECTO COMPLETO TULA 2000</li>
<li>

ESTACIÓN MANUEL PROYECTO COMPLETO</li>
<li>

ESTACIÓN DE SERVICIO MANTE PROYECTO COMPLETO</li>
<li>

SERVICIO DE AUTOCONSUMO TRANSPORTES TAMPIQUEÑOS PROYECTO COMPLETO</li>
<li>

SERVICIO AUTOCONSUMO GUME PROYECTO COMPLETO</li>
<li>

ESTACIÓN DE SERVICIO GUILLERMO AMADOR PROYECTO COMPLETO</li>
<li>

ESTACIÓN DE SERVICIO OZULUAMA VERACRUZ ELECTROMECÁNICO (3).</li>
<li>

ESTACIÓN DE SERVICIO MARINA VILLA CUAHUTEMOC ELECTROMECANICA</li>
<li>

ESTACION DE SERVICIOS GASOLINEROS CUAUHTÉMOC COMPLETO</li>
<li>

ESTACIÓN DE SERVICIO CUENCA S.A. de C.V. Boulevard Adolfo López Mateos #206 esq. Ave. Ejercito Mexicano Col. Loma del Gallo, Cd. Madero</li>
<li>

CONTROL ECOLÓGICO Y CONSTRUCCIONES S.A. DE C.V. Jalisco #205 altos Col. Primero de Mayo, Cd. Madero, Tamps.</li>
<li>

HELADOS MAFIS S.A. DE C.V. Calle Estadio #203 Col. Petrolera, Tampico, Tamps.</li>
<li>

HAMBURGUESAS MAFIS S.A. DE C.V. Calle Estadio #203 Col. Petrolera, Tampico, Tamps</li>
<li>

COMISIÓN FEDERAL DE ELECTRICIDAD. Av. Paseo de la Reforma # 164 Col. Juárez del Cuauhtémoc. México D.F.</li>
<li>

PUBLIPUENTES TAMAULIPAS S.A. DE C.V. Calle Primera #221 Col. Lomas del Chairel.</li>
<li>

ING. JOSE CHONG HERNANDEZ. Universidad de Wisconsin #803-9 Col. Las Villa</li>
<li>

ESTACIÓN DE SERVICIO GONELLI ,ELECTROMECANICA. Matamoros  Tamps</li>
<li>

ESTACIÓN DE SERVICIO CANOAS Electromecánica ,Supervisión Completa.</li>
<li>

ESTACIONES DE SERVICIO JIMENEZ,HIGO VERACRUZ,HUEJUTLA VER,SAN FELIPE ORIZATLAN HIDALGO (3) ELECTROMECANICA.</li>
<li>

GRUPO HIDROSINA 9 ESTACIONES DE SERVICIO EN SORIANAS</li>
<li>

GRUPO KUALE</li>
<li>

GRUPO NEXUM  (GAREL)</li>
            </ul>

            <h2>Contactanos</h2>
            <p>Por cualquiera de nuestros medios de contacto</p>

            <ul>
              <li>Via email: apycem@hotmail.com</li>
              <li>Via telefono: 8332168755</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
