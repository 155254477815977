import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview";
import FAQ from "components/faqs/SingleCol";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Helmet><title>APYCEM PROYECTOS ELECTRICOS MECANICOS Y CONSTRUCCION</title>
      <meta name="description" content="Trabajos especializados en obra eléctrica, obra mecánica, obra civil en estaciones de servicio, así como edificios, casas, construcciones nuevas y remodelaciones,"/> <link rel="canonical" href="/"/></Helmet>
      
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Trabajos</Subheading>}
        heading={
          <>
            Brindamos el mejor <HighlightedText>Servicio.</HighlightedText>
          </>
        }
        description="La atención en nuestros proyectos es prioridad, nuestros clientes y proyectos nos avalan."
      />
      {/* <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}
      <MainFeature2
        subheading={<Subheading>APYCEM</Subheading>}
        heading={
          <>
            ¿Quiénes{" "}
            <HighlightedText>Somos?.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Asequible",
            description:
              "Garantizamos manejar los mejores precios del sector, esto sin sacrificar la calidad y profesionalismo de nuestros trabajos.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Profesionalismo",
            description:
              "Nuestro equipo cuenta con profesionales altamente capacitados en todo tipo de procesos, que además cuentan con una gran experiencia trabajando.",
            iconContainerCss: tw`bg-blue-300 text-blue-800`,
          },
        ]}
      />
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [
              "30 Templates",
              "7 Landing Pages",
              "12 Internal Pages",
              "Basic Assistance",
            ],
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: [
              "60 Templates",
              "15 Landing Pages",
              "22 Internal Pages",
              "Priority Assistance",
            ],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
              "90 Templates",
              "27 Landing Pages",
              "37 Internal Pages",
              "Personal Assistance",
            ],
          },
        ]}
      /> */}
      <Testimonial
        subheading={<Subheading>Proyectos</Subheading>}
        heading={
          <>
            Nuestros Proyectos <HighlightedText>Hablan por Nosotros</HighlightedText>
          </>
        }
        testimonials={[
          {
            imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/WhatsApp%20Image%202022-12-13%20at%2019.42.02%20(1).jpeg?alt=media&token=953246b2-3e61-43cd-9502-b2ceb44ae906",
            profileImageSrc:
              "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/74674690_589258438339037_7282693568321920225_n.jpg?alt=media&token=1f6c3cce-8513-48ab-874d-d72460be3c8e",
            quote:
              "Desde ya hace más de 26 años siempre hemos trabajado de forma honesta e innovadora, siempre con una visión hacia el futuro y cumpliendo con todos los requerimientos que nuestros clientes soliciten.",
            customerName: "Ingeniero Cruz R. Guerrero",
            customerTitle: "Director APYCEM",
          },
          {
            imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/WhatsApp%20Image%202022-12-13%20at%2019.45.31.jpeg?alt=media&token=2d10f8f1-acb0-48b2-af02-c19104e1621b",
            profileImageSrc:
              "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/74674690_589258438339037_7282693568321920225_n.jpg?alt=media&token=1f6c3cce-8513-48ab-874d-d72460be3c8e",
            quote:
              "Desde ya hace más de 26 años siempre hemos trabajado de forma honesta e innovadora, siempre con una visión hacia el futuro y cumpliendo con todos los requerimientos que nuestros clientes soliciten.",
            customerName: "Ingeniero Cruz R. Guerrero",
            customerTitle: "Director APYCEM",
          },{
            imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/WhatsApp%20Image%202022-12-13%20at%2019.42.02%20(2).jpeg?alt=media&token=ea316339-3864-44bd-9a2f-e0c79799d126",
            profileImageSrc:
              "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/74674690_589258438339037_7282693568321920225_n.jpg?alt=media&token=1f6c3cce-8513-48ab-874d-d72460be3c8e",
            quote:
              "Desde ya hace más de 26 años siempre hemos trabajado de forma honesta e innovadora, siempre con una visión hacia el futuro y cumpliendo con todos los requerimientos que nuestros clientes soliciten.",
            customerName: "Ingeniero Cruz R. Guerrero",
            customerTitle: "Director APYCEM",
          },{
            imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/DSCF1178.JPG?alt=media&token=6c2528a9-1f99-4a85-b681-ffc033e13c92",
            profileImageSrc:
              "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/74674690_589258438339037_7282693568321920225_n.jpg?alt=media&token=1f6c3cce-8513-48ab-874d-d72460be3c8e",
            quote:
              "Desde ya hace más de 26 años siempre hemos trabajado de forma honesta e innovadora, siempre con una visión hacia el futuro y cumpliendo con todos los requerimientos que nuestros clientes soliciten.",
            customerName: "Ingeniero Cruz R. Guerrero",
            customerTitle: "Director APYCEM",
          },{
            imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/DSCF0721.JPG?alt=media&token=a99ede77-6f0f-45c3-81c1-3c62329cc934",
            profileImageSrc:
              "https://firebasestorage.googleapis.com/v0/b/apycemweb.appspot.com/o/74674690_589258438339037_7282693568321920225_n.jpg?alt=media&token=1f6c3cce-8513-48ab-874d-d72460be3c8e",
            quote:
              "Desde ya hace más de 26 años siempre hemos trabajado de forma honesta e innovadora, siempre con una visión hacia el futuro y cumpliendo con todos los requerimientos que nuestros clientes soliciten.",
            customerName: "Ingeniero Cruz R. Guerrero",
            customerTitle: "Director APYCEM",
          },
        ]}
      />
      <FAQ
        subheading={<Subheading>Preguntas Frecuentes</Subheading>}
        heading={
          <>
            ¿Tienes alguna <HighlightedText>duda?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "¿Que trabajos realiza APYCEM?",
            answer:
              "Servicio de mantenimiento eléctrico-mecánico a estaciones de servicio (hidrocarburos) así como trabajos especializados en obra eléctrica, obra mecánica, obra civil en estaciones de servicio, así como edificios, casas,construcciones nuevas y remodelaciones.",
          },
          {
            question:
              "¿Cómo cotizar?",
            answer:
              "Por este medio, solo da clic en enlace de contacto y envianos un mensaje con un la idea que tienes en mente, así como tus datos de contacto. Posteriormente nos comunicaremos contigo para conocer todos los detalles de tu proyecto y poder trabajar juntos.",
          },
          {
            question: "¿En qué parte de la república se encuentran?",
            answer:
              "Nosotros estamos situados en Ciudad Madero, Tamaulipas. Sin embargo realizamos y hemos realizado trabajos en distintas partes de la república, especializándonos en la región noreste de México.",
          },
          
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
